import * as React from 'react';
import { Link } from 'gatsby';

import Seo from '../components/inc/seo';
import Layout from '../components/layout';

import Header from '../components/header';
import Footer from '../components/footer';

import BtnArrow from '../images/btn_arrow.svg';

const NotFoundPage = () => {
  return (
    <>
      <Seo />
      <Header pageNotFound></Header>
      <main className='page404'>
        <Layout>
          <div className='pageNotFoundContent'>
            <h1 className='title'>
              <span className='bigText'>404</span> <br />
              Page not found
            </h1>
            <p className='underTitle'>
              Sorry{' '}
              <span role='img' aria-label='Pensive emoji'>
                😔
              </span>{' '}
              we couldn’t find what&nbsp;you&nbsp;were looking&nbsp;for.
              <br />
              {/* {process.env.NODE_ENV === 'development' ? (
            <>
              <br />
              Try creating a page in <code style={codeStyles}>src/pages/</code>.
              <br />
            </>
          ) : null} */}
              <br />
            </p>
            <div className='buttonContainer'>
              <Link to='/'>
                <div className='button'>
                  <p className={'buttonText'}>Go home</p>
                  <img src={BtnArrow} alt='Button Arrow' />
                </div>
              </Link>
            </div>
          </div>
        </Layout>
      </main>
      <Footer pageNotFound />
    </>
  );
};

export default NotFoundPage;
